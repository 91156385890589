@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

img[alt~='roulette-static'] {
	width: 10%;
	right: 8%;
	top: 8%;
}

.shadow-effect {
	box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
}
